
import { Vue } from "vue-class-component";
import axios from 'axios'

export default class HelloWorld extends Vue {
  themeVars = {
    buttonPrimaryBorderColor: "#fc653c",
    buttonPrimaryBackgroundColor: "#fc653c",
  };
  username = "";
  password = "";
  showPop = false;
  changePPObj = {
    username: '',
    oldP: '',
    newP: '',
    newP2: ''
  }
  mounted() {
    const un = window.localStorage.getItem('username');
    // const pw = window.localStorage.getItem('password');
    if(un) this.username = un;
    // if(pw) this.password = pw;
  }
  onSubmit(values: { username: string; password: string }): void {
    this.post('https://app.mezolen.com/api/login', values).then( (res: any) => {
      if(res.is_success) {
        this.$notify({
          message: '登录成功',
          type: 'success',
          onOpened: () => {
            if(res.data){
              window.localStorage.setItem('bjcgCount', res.data.purchaser_todo_count)
              window.localStorage.setItem('cwglCount', res.data.finance_todo_count)
              window.localStorage.setItem('scjcCount', res.data.pqa_todo_count)
              window.localStorage.setItem('jlcxCount', res.data.manager_todo_count)
              window.localStorage.setItem('htddCount', res.data.sales_todo_count)
              window.localStorage.setItem('username', values.username)
              // window.localStorage.setItem('password', values.password)
            }
            this.$router.push("/home")
          }
        })
      }else {
        this.$notify({
        message: '用户名或密码错误',
        color: 'white',
        background: '#fc653c'
      })
      }
    }).catch(err => {
      this.$notify({
        message: '用户名或密码错误',
        color: 'white',
        background: '#fc653c'
      })
    })
  }
  openChangePP() {
    this.changePPObj = {
      username: '',
      oldP: '',
      newP: '',
      newP2: ''
    }
    this.showPop = true;
  }
  onSubmitPP(values: {username: string, old_password: string, new_password: string, confirm_password: string}) {
    this.post('https://app.mezolen.com/api/change_password', values).then( (res: any) => {
      if(res.is_success) {
        this.$notify({
          message: '修改成功',
          type: 'success',
          onOpened: ()=>{this.showPop=false}
        })
      }else {
        this.$notify({
        message: res.result_msg,
        color: 'white',
        background: '#fc653c'
      })
      }
    }).catch(err => {
      this.$notify({
        message: '修改失败，请重试',
        color: 'white',
        background: '#fc653c',
      })
    })
  }
  validatorFunc(value: string) {
    if(value==this.changePPObj.newP) return true
    return false
  }
  post(url: string, params: any, callback?: any) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, params)
        .then(res => {
          callback ? resolve(callback(res.data)) : resolve(res.data)
        })
        .catch(err => reject(err))
    })
  }
}
