
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class Home extends Vue {
  urlList = {
    order: "https://app.mezolen.com/admin/business/contractsalesview/",
    purchase: "https://app.mezolen.com/admin/business/contractpurchaserview/",
    transport: "https://app.mezolen.com/admin/business/contractexpressview/",
    finance: "https://app.mezolen.com/admin/business/contractfinanceview/",
    production: "https://app.mezolen.com/admin/business/contractpqaview/",
    managerSearch: "https://app.mezolen.com/admin/business/contractmanagerview/"
  };
  rightPostion = '124px'
  bjcgCount = ''
  cwglCount = ''
  scjcCount = ''
  jlcxCount = ''
  htddCount = ''
  mounted(): void {
    const right = this.$refs['rightStep'] as HTMLAreaElement
    const center = this.$refs['centerStep'] as HTMLAreaElement
    this.rightPostion = `${(right.getBoundingClientRect().left - center.getBoundingClientRect().right)/2 + 100}px`
    this.bjcgCount = window.localStorage.getItem('bjcgCount') || ''
    this.cwglCount = window.localStorage.getItem('cwglCount') || ''
    this.scjcCount = window.localStorage.getItem('scjcCount') || ''
    this.jlcxCount = window.localStorage.getItem('jlcxCount') || ''
    this.htddCount = window.localStorage.getItem('htddCount') || ''
  }
  navTo(url: string): void {
    window.open(url, '_self')
  }
}
