import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { ConfigProvider, Button, Notify, Form, Field, CellGroup, Popup } from 'vant'
import 'vant/lib/index.css';

const app = createApp(App)
app.use(router)
app.use(ConfigProvider)
app.use(Form)
app.use(Field)
app.use(CellGroup)
app.use(Button)
app.use(Notify)
app.use(Popup)

app.mount('#app')
